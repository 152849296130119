import store, { RootState } from '../../../App/store';
import { paramsNotFound } from '../../../services/axiosFiles/axiosUtils';
import { postEntity } from '../../../services/axiosFiles/genericCrud';
import { studyProcedureApiToStoreParser } from '../utils/parsers/studyProcedureParser';

async function createStudyProcedure(body: StudyProcedureExtended) {
  try {
    const state = store.getState() as RootState;
    const { procedureTypes, contactRoles } = state.app;
    const { users } = state.users;
    const { type } = state.studyParams;
    if (
      body &&
      body.studyIdIri &&
      contactRoles.result &&
      procedureTypes.result &&
      users
    ) {
      const filteredBody: any = {
        ...body,
        [type as string]: body.studyIdIri,
      };
      delete filteredBody.studyIdIri;

      const result = await postEntity({
        endpoint: '/procedures',
        body: filteredBody,
      });

      const parsedResult = studyProcedureApiToStoreParser(
        result,
        users,
        contactRoles.result,
        procedureTypes.result
      );

      return parsedResult;
    } else {
      return Promise.reject(paramsNotFound());
    }
  } catch (error) {
    return Promise.reject(error);
  }
}

export default createStudyProcedure;
