import { Button, TextField } from '@mui/material';
import React, { KeyboardEvent, memo, useState } from 'react';

interface IGenericResearchProps {
  setSearchPattern: React.Dispatch<React.SetStateAction<string>>;
  placeHolder?: string;
}
function GenericInputResearch({
  setSearchPattern,
  placeHolder = '',
}: IGenericResearchProps) {
  const [value, setValue] = useState<string>('');

  const handleClick = () => {
    setSearchPattern(value);
  };

  const handleKeyPress = (e: KeyboardEvent<HTMLDivElement>) => {
    const key = e.key;

    if (key === 'Enter') {
      handleClick();
    }
  };

  return (
    <div className="generic-search">
      <TextField
        variant="outlined"
        className="input-research"
        placeholder={placeHolder}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onKeyPress={handleKeyPress}
      />
      <Button className="research-btn button-base" onClick={handleClick}>
        Rechercher
      </Button>
    </div>
  );
}

export default memo(GenericInputResearch);
