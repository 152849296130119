import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { isObject } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';

function MuiSelectCustom({
  label,
  value,
  name,
  onChange,
  items,
  autoWidth,
  placeholder,
  disabled,
  displayKey = 'text',
  valueKey = 'value',
}) {
  return (
    <FormControl variant="outlined" disabled={disabled}>
      {label ? <InputLabel shrink>{label}</InputLabel> : null}
      <Select
        name={name}
        value={value || ''}
        notched
        displayEmpty
        onChange={onChange}
        label={label}
        autoWidth={autoWidth}
      >
        <MenuItem disabled value="">
          {placeholder}
        </MenuItem>
        {items.map((elt) => (
          <MenuItem key={uuidv4()} value={isObject(elt) ? elt[valueKey] : elt}>
            {isObject(elt) ? elt[displayKey] : elt}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

MuiSelectCustom.defaultProps = {
  items: [],
};
MuiSelectCustom.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  displayKey: PropTypes.string,
  valueKey: PropTypes.string,
  items: PropTypes.array.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired,
  ]),
  onChange: PropTypes.func.isRequired,
  colorDisplay: PropTypes.string,
  autoWidth: PropTypes.bool,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
};

export default MuiSelectCustom;
