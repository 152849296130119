import BarChartIcon from '@mui/icons-material/BarChart';
import { useAppDispatch } from '../../App/store';
import { fetchFolderPlotStudiesForExcel } from '../../features/folders/services/thunks/foldersThunk';

import { IIconActionProps } from './ItemActions';
import TooltipIcon from './TooltipIcon';
import styles from './itemActions.module.scss';

function ExportIcon<T>({ item }: IIconActionProps<T>) {
  const dispatch = useAppDispatch();

  const handleEditCsv = () => {
    dispatch(fetchFolderPlotStudiesForExcel({ folder: item as IFolder }));
  };

  return (
    <TooltipIcon action={'Editer tableau excel'}>
      <BarChartIcon className={styles.genericIcon} onClick={handleEditCsv} />
    </TooltipIcon>
  );
}

export default ExportIcon;
