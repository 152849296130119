import { useEffect } from 'react';
import { getAppState } from '../../App/appSlice';
import { useAppDispatch, useAppSelector } from '../../App/store';
import { getPlotState } from '../../redux/plot/reducer';
import { APIStatus } from '../../services/axiosFiles/apiTypes';
import { fetchSyntheseFolderThunk } from '../folders/services/thunks/foldersThunk';
import useFolder from '../folders/useFolder';
import { getUsersState } from '../users/usersSlice';
import { checkAndUpdatePlotStudyDatas } from './services/checkAndUpdatePlotStudyDatas';
import {
  checkExistingPlotStudyThunk,
  constructStudyDatasThunk,
} from './services/thunks/studyThunks';
import { getStudyParamsState } from './slices/studyParamsSlice';
import { getStudyState, studyActions } from './slices/studySlice';

export default function useStudy() {
  const { folders } = useFolder({});
  const studyParams = useAppSelector(getStudyParamsState);
  const { parcelle } = useAppSelector(getPlotState);
  const { contactRoles, plotStudyStatuses, procedureTypes } = useAppSelector(
    getAppState
  );
  const { users } = useAppSelector(getUsersState);
  const { study } = useAppSelector(getStudyState);
  const { studyPlotStudies } = useAppSelector(getStudyState);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (
      studyParams.idIri &&
      studyParams.type &&
      !study.result &&
      users &&
      contactRoles.result &&
      plotStudyStatuses.result &&
      procedureTypes.result &&
      folders
    ) {
      dispatch(
        constructStudyDatasThunk({
          type: studyParams.type,
          idIri: studyParams.idIri,
        })
      );
    }
  }, [
    studyParams.idIri,
    users,
    contactRoles.result,
    plotStudyStatuses.result,
    procedureTypes.result,
    folders,
  ]);

  const checkPsDatas = async (ps: IPlotStudy, p: IPlot) => {
    if (
      (p.zone.zone !== ps.zone ||
        p.zone.zoneChild !== ps.zoneChild ||
        parseInt(p.theoricCapacity) !== ps.area) &&
      study.apiStatus === APIStatus.IDLE
    ) {
      const newPs = await checkAndUpdatePlotStudyDatas({
        ps,
        zones: p.zone,
        surface: +p.theoricCapacity,
      });

      dispatch(studyActions.studySet(newPs));
    }
  };

  useEffect(() => {
    if (studyPlotStudies.result) {
      dispatch(fetchSyntheseFolderThunk({ plotStudies: studyPlotStudies.result }));
    }
  }, [studyPlotStudies.result]);

  useEffect(() => {
    if (study.result && parcelle) {
      const ps = study.result as IPlotStudy;

      if (ps.fullPlotId === parcelle.parcelleId) {
        checkPsDatas(ps, parcelle);
      }
    }
  }, [study.result, parcelle]);

  useEffect(() => {
    if (studyParams.type === 'plotStudy' && parcelle && folders) {
      if (!studyParams.noCheckExisting && !study.result) {
        dispatch(checkExistingPlotStudyThunk({ fullPlotId: parcelle.parcelleId }));
      }

      if (study.result) {
        const ps = study.result as IPlotStudy;

        if (ps.fullPlotId === parcelle.parcelleId) {
          checkPsDatas(ps, parcelle);
        }
      }
    }
  }, [parcelle, studyParams, folders]);

  return {
    study: study.result,
    studyParams,
  };
}
