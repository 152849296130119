import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { nanoid } from '@reduxjs/toolkit';
import { delay } from 'lodash';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../App/store';
import { georisquesActions, getGeorisqueState } from '../georisqueSlice';
import SectionCheckboxContainer from './SectionCheckboxContainer';
import styles from './georisqueLegend.module.scss';
interface ILegendSectionProps {
  section: GeorisqueLegend;
}

function LegendSection({ section }: ILegendSectionProps) {
  const [isOpen, setIsOpen] = useState(false);
  const { displayedLayers } = useAppSelector(getGeorisqueState);
  const dispatch = useAppDispatch();
  const isChecked = displayedLayers.some((s) => s.includes(section.title));
  const handleChange = () => {
    const values = section.layers.map((m) => m.layer + '/' + section.title);
    dispatch(georisquesActions.multiRowCheck({ values, check: !isChecked }));

    delay(() => {
      setIsOpen(!isChecked);
    }, 1);
  };

  const handleOpenClick = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const finded = displayedLayers.find((f) => f.indexOf(section.title));

    if (!finded) {
      delay(() => {
        setIsOpen(false);
      }, 1);
    }
  }, [displayedLayers]);

  return (
    <div className={styles.legendSection}>
      <div className={styles.legendSectionHeader}>
        <div className={styles.headerCheckbox}>
          <input
            type="checkbox"
            name={section.title}
            checked={isChecked}
            onChange={handleChange}
          />
          <h3>{section.title}</h3>
        </div>

        <ExpandMoreIcon
          className={`${styles.expandIcon} ${isOpen ? styles.isOpen : ''}`}
          onClick={handleOpenClick}
        />
      </div>

      {isOpen && <SectionCheckboxContainer key={nanoid()} checkboxs={section} />}
    </div>
  );
}

export default LegendSection;
