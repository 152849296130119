import { paramsNotFound } from '../../../services/axiosFiles/axiosUtils';
import { fetchDatas } from '../../../services/axiosFiles/genericCrud';
import { studyContactsApiToStoreParser } from '../utils/parsers/studyContactParser';

export const fetchStudyContacts = async (
  studyIdIri: string | null,
  companyId: number | null,
  contactRoles: ContactRoles | null
) => {
  try {
    if (studyIdIri && companyId && contactRoles) {
      const studyContacts = await fetchDatas(`${studyIdIri}/contacts`, {
        company_id: companyId,
      });

      if (studyContacts && studyContacts['hydra:member']) {
        const parsedResult = studyContactsApiToStoreParser(
          studyContacts['hydra:member'],
          contactRoles
        );

        return parsedResult;
      } else return [];
    } else {
      return Promise.reject(paramsNotFound('fetchStudyContacts'));
    }
  } catch (error) {
    return Promise.reject(error);
  }
};
