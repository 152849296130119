import { isEmpty } from 'lodash';

export function genericEntitySort<T extends ISortable>(
  tab: T[] = [],
  sortKey: string,
  order: Order
): T[] {
  if (isEmpty(tab) || !sortKey) {
    return tab;
  }

  const sorted = [...tab].sort((a, b) => {
    const key = sortKey as keyof typeof a.sortValues;
    const aValue = a.sortValues[key] ?? null;
    const bValue = b.sortValues[key] ?? null;

    if (aValue === null) return 1;
    if (bValue === null) return -1;
    if (bValue === aValue) return 0;

    if (typeof aValue === 'string' && typeof bValue === 'string') {
      return order === 'asc'
        ? aValue.localeCompare(bValue, undefined, { caseFirst: 'lower' })
        : bValue.localeCompare(aValue, undefined, { caseFirst: 'lower' });
    } else {
      return order === 'asc' ? (bValue > aValue ? -1 : 1) : aValue > bValue ? -1 : 1;
    }
  });

  return sorted;
}
