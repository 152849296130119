import { Checkbox, FormControlLabel } from '@mui/material';
import { useAppDispatch } from '../../../App/store';
import { IPluOthers } from '../../../services/parserDatas/pluParser';
import { pluActions } from '../pluSlice';
import styles from './pluOther.module.scss';

interface IPluOtherProps {
  cat: IPluOthers;
}
function PluOtherRow({ cat }: IPluOtherProps) {
  const dispatch = useAppDispatch();

  const onChange = () => {
    dispatch(pluActions.pluOtherDisplay(cat.libelle));
  };

  return (
    <FormControlLabel
      className={styles.pluOtherRow}
      control={
        <Checkbox
          color="primary"
          checked={cat.displayed}
          onChange={onChange}
          inputProps={{ 'aria-label': 'secondary checkbox' }}
        />
      }
      label={cat.libelle}
    />
  );
}

export default PluOtherRow;
