import { memo } from 'react';
import createProspectionTabCtx from '../../../App/contexts/ProspectionTabContext';
import useStudy from '../../../features/study/useStudy';
import TabsContentBody from './TabsContentBody';
import TabsContentHeader from './TabsContentHeader';

const [ctx, ProspectionTabProvider] = createProspectionTabCtx();
export const ProspectionTabContext = ctx;

function TabContentContainer() {
  useStudy();

  return (
    <ProspectionTabProvider>
      <div className="right-panel-modal-header">
        <TabsContentHeader />
      </div>

      <div className="right-panel-modal-body">
        <TabsContentBody />
      </div>
    </ProspectionTabProvider>
  );
}

export default memo(TabContentContainer);
