import { paramsNotFound } from '../../../services/axiosFiles/axiosUtils';
import { deleteEntity } from '../../../services/axiosFiles/genericCrud';

async function deleteStudyProcedure(idIri: string) {
  try {
    if (idIri) {
      await deleteEntity(idIri);

      return idIri;
    } else {
      return Promise.reject(paramsNotFound('deleteStudyProcedure'));
    }
  } catch (error) {
    return Promise.reject(error);
  }
}

export default deleteStudyProcedure;
