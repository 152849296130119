import { nanoid } from '@reduxjs/toolkit';
import { useEffect } from 'react';
import { appActions } from '../../App/appSlice';
import useRightPanel from '../../App/hooks/useRightPanel';
import { useAppDispatch, useAppSelector } from '../../App/store';
import { getAuthState } from '../../features/auth/authSlice';
import './accountTabs.scss';
import { AccountTabsValues } from './constant';

interface IAccountTabsProps {
  selectedTab: AccountTab;
}

const AccountTabs = ({ selectedTab }: IAccountTabsProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const { isManager } = useAppSelector(getAuthState);
  const { closeRightPanel, rightPanelDisplay } = useRightPanel();

  const handleTabChange = (value: AccountTab) => {
    if (rightPanelDisplay) {
      closeRightPanel();
    }
    dispatch(appActions.setAccountTab(value));
  };

  useEffect(() => {
    return () => {
      closeRightPanel();
    };
  }, []);

  return (
    <div className={`account-tabs`}>
      {AccountTabsValues.filter((elt) =>
        isManager ? elt : elt.role.includes('user')
      ).map((el) => (
        <div
          key={nanoid()}
          onClick={() => handleTabChange(el.id as AccountTab)}
          className={`account-tab ${selectedTab === el.id ? 'selected' : ''}`}
        >
          {el.title}
        </div>
      ))}
    </div>
  );
};

export default AccountTabs;
