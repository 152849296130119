import Tooltip from '@mui/material/Tooltip';
import useProcedureTypes from '../../../../../App/hooks/useProcedureTypes';
import { useStylesFunc } from '../../../../../components/folderAndSectorActions/style';

interface IProcedureTypeProps {
  type?: ProcedureType | string;
  className?: string;
  tooltip?: boolean;
}

const ProcedureType = ({ type, className, tooltip }: IProcedureTypeProps) => {
  const { getIcon, findProcedureType } = useProcedureTypes();
  const useStyles = useStylesFunc({ isPrimary: true });
  const { classes } = useStyles();
  let procedureType: any = '';

  if (typeof type === 'string') {
    procedureType = findProcedureType(type);
  } else {
    procedureType = type;
  }

  if (!tooltip) {
    return (
      <div>
        <img
          src={getIcon(procedureType?.idIri)}
          alt="type icon"
          className={className}
        />
        <span>{procedureType?.label}</span>
      </div>
    );
  }

  return (
    <Tooltip
      title={procedureType?.label ?? ' '}
      placement="top"
      arrow
      classes={{
        tooltip: classes.tooltip,
        arrow: classes.arrow,
      }}
    >
      <img
        src={getIcon(procedureType?.idIri)}
        alt="type icon"
        className={className}
      />
    </Tooltip>
  );
};

export default ProcedureType;
