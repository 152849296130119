import { useContext, useEffect } from 'react';
import PaginationCustom from '../../../components/Common/PaginationCustom/PaginationCustom';
import { contactContext } from '../../study/components/studyContact/rightPanelContactBloc/StudyContactContent';
import ContactInputSearch from './ContactInputSearch';
import ContactTable from './ContactTable';
import styles from './contactSearchModal.module.scss';

interface IContactModalContentProps {
  onCloseModal: () => void;
}

function ContactModalContent({ onCloseModal }: IContactModalContentProps) {
  const {
    itemsPerPage,
    setItemsPerPage,
    page,
    setPage,
    contacts,
    setContacts,
  } = useContext(contactContext);

  useEffect(() => {
    return () => {
      setContacts(null);
    };
  }, []);

  const handleItemsPerPageChange = (e: ChangeEventCustom) => {
    const value = e.target.value as number;
    setItemsPerPage(value);
  };

  const handleIndexChange = (index: number): void => {
    const value = (Math.floor(index / itemsPerPage) + 1) as number;
    setPage(value);
  };

  return (
    <div className={styles.contactModalContent}>
      <ContactInputSearch />

      <ContactTable
        contacts={
          contacts?.filter(
            (c, i) => i >= (page - 1) * itemsPerPage && i < page * itemsPerPage
          ) ?? []
        }
        onCloseModal={onCloseModal}
      />

      {(contacts?.length ?? 0) > 0 && (
        <PaginationCustom
          elementsQty={contacts?.length ?? 0}
          itemsPerPage={itemsPerPage}
          onItemsPerPageChange={handleItemsPerPageChange}
          onMinIndexTab={handleIndexChange}
        />
      )}
    </div>
  );
}

export default ContactModalContent;
