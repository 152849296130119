import Tooltip from '@mui/material/Tooltip';
import { useEffect, useState } from 'react';
import { useStylesFunc } from './style';

interface ITooltipIconProps {
  action: string;
  children: React.ReactElement;
}

function TooltipIcon({ action, children }: ITooltipIconProps) {
  const [isPrimary, setIsPrimary] = useState(true);
  const useStyles = useStylesFunc({ isPrimary });
  const { classes } = useStyles();

  useEffect(() => {
    switch (action) {
      case 'Supprimer dossier':
      case 'Supprimer sous-dossier':
      case 'Supprimer secteur':
      case 'Supprimer sous-secteur':
      case 'Editer secteur':
      case 'Ajouter sous-secteur':
        setIsPrimary(false);
        break;
      default:
        setIsPrimary(true);
        break;
    }
  }, [action]);

  return (
    <Tooltip
      title={action}
      placement="top"
      arrow
      classes={{
        tooltip: classes.tooltip,
        arrow: classes.arrow,
      }}
    >
      {children}
    </Tooltip>
  );
}

export default TooltipIcon;
