import Pagination from '@mui/lab/Pagination';
import { useEffect, useState } from 'react';
import MuiSelectCustom from '../MuiSelectCustom';
import styles from './paginationCustom.module.scss';

interface ICustomPaginatinProps {
  elementsQty: number;
  itemsPerPage: number;
  onMinIndexTab?: (x: number) => void;
  onItemsPerPageChange: ChangeEventCustom;
  variant?: 'outlined' | 'text' | undefined;
  itemPerPageLabel?: string;
  forceDisplay?: boolean;
  page?: number | null;
}

function PaginationCustom({
  elementsQty,
  itemsPerPage,
  onMinIndexTab,
  onItemsPerPageChange,
  variant = 'outlined',
  itemPerPageLabel,
  forceDisplay,
  page,
}: ICustomPaginatinProps): JSX.Element {
  const [activePage, setActivePage] = useState<number>(page ?? 1);
  const [pageQty, setPageQty] = useState<nullable<number>>(null);
  const [itemPerPageTab, setItemPerPageTab] = useState<number[]>([]);

  useEffect(() => {
    const tab = [5];
    let count = 5;
    if (elementsQty) {
      while (count < elementsQty && count <= 25) {
        count += 5;
        tab.push(count);
      }
      setItemPerPageTab(tab);
    }
  }, [elementsQty]);

  useEffect(() => {
    const newPageQty = Math.ceil(elementsQty / itemsPerPage);
    setPageQty(newPageQty);

    if (newPageQty === 0) {
      setActivePage(1);
    } else if (activePage > newPageQty) {
      setActivePage(newPageQty);
    }
  }, [elementsQty, itemsPerPage]);

  useEffect(() => {
    if (onMinIndexTab) {
      onMinIndexTab(itemsPerPage * (activePage - 1));
    }
  }, [activePage]);

  const handleChange = (page: number): void => {
    setActivePage(page);
    window.scrollTo(0, 0);
  };

  return (
    <>
      {((pageQty || 0) > 1 || forceDisplay) && (
        <div className={styles.pagination}>
          <Pagination
            page={activePage}
            count={pageQty || 0}
            onChange={(evt, page) => handleChange(page)}
            variant={variant}
          />
          {itemPerPageLabel && (
            <div className={styles.itemsPerPage}>
              <span>{itemPerPageLabel}</span>
              <MuiSelectCustom
                name="itemPerPage"
                value={itemsPerPage}
                items={itemPerPageTab}
                onChange={onItemsPerPageChange}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default PaginationCustom;
